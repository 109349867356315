@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import 'font-awesome/css/font-awesome.min.css';

.app {
  font-family: 'Bangers', cursive;
  background-color: #f5f5f5;
  color: #000;

  main {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .comic-section {
    position: relative;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    font-size: 1.2rem;
  }

  .home-background {
    background-image: url('../public/ranch.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding: 0;
    // background-color: darkolivegreen;
  }
    
  .home-image {
    background-image: url('../public/comic.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 999;
    height: 100vh;
    // background-color: darkolivegreen;
  }

  .home-image {
  }
    
  #journey {
    background-color: cornflowerblue;
  }
    
  #contact {
    color: #fff;
    background-image: url('../public/background.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  #journey, #contact {
    border: 5px dashed #000;
    width: 75%;
    margin: auto;
    height: 100%;
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  
  h3 {
    font-size: 1.5rem;
    color: #000;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  footer {
    padding: 20px;
    background: #f5f5f5;
    border-top: 4px solid #000;
    text-align: center;
  }

  .experience-container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .experience {
    margin-bottom: 40px;
    padding: 15px;
    background-color: #fff;
    border: 3px solid #000;
    position: relative;
    display: inline-block;
    font-size: 1rem;
    max-width: 80%;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
    position: relative;
  }

  .experience:not(:last-child):after {
    content: ""; /* Create a pseudo-element */
    display: block;
    height: 20px; /* Set the height of the line */
    border: 2px solid #000; /* Style the line */
    width: 100%; /* Make the line the full width of the container */
    position: absolute;
    left: 50%;
    top: 100%;
    height: 55px;
    width: 0;
  }

  .socials {
    display: flex;
    gap: 20px; /* Adjust the gap between the icons */
    justify-content: center;

    a {
      color: lime;
    }
  }

  .socials a:hover {
    text-decoration: none;
  }

  .scroller {
    position: fixed;
    right: 20px;
    height: 42px;
    width: 40px;
    cursor: pointer;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
  }

  .scroll-up {
    bottom: 65px; /* Position above the other button */
    background-image: url('../public/uparrow.jpg');
  }

  .scroll-down {
    bottom: 20px; /* Position above the other button */
    background-image: url('../public/downarrow.jpg');
  }
}
